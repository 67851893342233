<template>
	<section class="section-wrapper">
		<div class="help-inner-wrapper">
			<div class="slider">
				<div class="slide">
					<div class="main-photo-wrapper">
						<cms-image
							v-for="(image, i) in this.value.slider"
							:key="`${image}.${i}.slide_photo.path`"
							class="slide-img"
							:value="`${prefix}.slider.${i}.slide_photo`"
							:class="{
								active: i === activeSlideIndex,
								prev: i < activeSlideIndex,
								next: i > activeSlideIndex
							}"
							:base="{width: 604, height: 540}"
							:sm="{width: 800, height: 540}"
							:md="{width: 1000, height: 540}"
							:lg="{width: 1400, height: 546}"
							:xl="{width: 1920, height: 546}"
							:preload="i === 0"
						/>
					</div>
					<slider-content :activeSlide="activeSlideIndex" :prefix="prefix" :value="value"/>
					<slider-product :activeSlide="activeSlideIndex" :prefix="prefix" :value="value"/>
				</div>
				<slider-pagination
					@goToHandler="goTo"
					:activeSlide="activeSlideIndex"
					:length="this.value.slider.length"
				/>
			</div>
		</div>
	</section>
</template>

<script>
import SliderContent from './components/SliderContent'
import SliderPagination from './components/SliderPagination'
import SliderProduct from './components/SliderProduct'

export default {
	data: () => ({ activeSlideIndex: 0 }),
	components: {
		SliderContent,
		SliderProduct,
		SliderPagination
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		goTo (index) {
			this.activeSlideIndex = index
			clearInterval(this.timer)
			this.startInterval()
		},
		startInterval () {
			this.timer = setInterval(this.next, 5000)
		},
		next () {
			if (this.activeSlideIndex === this.value.slider.length - 1) this.activeSlideIndex = 0
			else this.activeSlideIndex++
		}
	},
	mounted () {
		this.startInterval()
	}
}
</script>
<style lang="scss" scoped>
.help-inner-wrapper {
	overflow: hidden;
    height: 100%;
    padding-bottom: 120px;
    margin-bottom: -120px;
}
.section-elements {
	& :deep(:first-child)  {
		margin-top: 0;
	}
}
.main-photo-wrapper {
	position: relative;
	overflow: hidden;

	&::after{
		content: '';
		height: 100%;
		width: 100%;
		background-color: rgba(0,0,0,0.25);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;

	}
}
.slider {
	position: relative;
	margin-bottom: 2rem;
}
.slide {
	position: relative;
	&::before {
		position: absolute;
		content: '';
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		border: 1px solid rgba($white, 0.6);
		z-index: 100;
		pointer-events: none;
	}

	&-img {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: all .5s ease;
		pointer-events: none;
		&.prev {
			transform: translateX(100px);
			z-index: 2;
		}
		&.next {
			transform: translateX(-100px);
		}
		&.active {
			position: relative;
			transform: translateX(0);
			opacity: 1;
			pointer-events: inherit;
		}
		:deep(img) {
			display: block;
			object-fit: cover;
			height: 540px;
			@media screen and (min-width:1921px) {
				width: 100%;
			}
		}
	}
}
</style>
