<template>
<div class="slider-pagination">
	<base-container>
		<nav>
			<ul class="pagination-list">
				<li
					v-for="(item, i) in length"
					:key="i"
					@click.stop="$emit('goToHandler', i)"
					:class="{
						active: i === activeSlide
					}"
					class="pagination-item"
				></li>
			</ul>
		</nav>
	</base-container>
</div>
</template>
<script>
export default {
	props: {
		length: Number,
		activeSlide: Number
	}
}
</script>
<style lang="scss" scoped>
.slider-pagination {
	width: 100%;
	position: absolute;
	top: 220px;
	z-index: 120;
	left: 50%;
	transform: translateX(-50%);
	@include media-breakpoint-up(md) {
		top: 45%;
	}
	@include media-breakpoint-up(xl) {
		top: 285px;
	}
	.pagination-list {
		display: flex;
		list-style: none;
		padding: 0 40px 0;
		margin: 16px 0;
		padding: 0;
	}
	.pagination-item {
		background-color: rgba($white, .3);
		width: 20px;
		height: 7px;
		margin-right: 1rem;
		cursor: pointer;
		@include transition;
		&.active {
			width: 40px;
			background-color: $white;
		}
	}
}
</style>
