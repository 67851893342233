<template>
	<div class="slide-product-wrapper">
		<div class="images-wrapper product-bg-images">
			<template
				v-for="(image, i) in this.value.slider"
			>
				<cms-image
					v-if="isImagePathCorrect(`${prefix}.slider.${i}.slide_product_photo_background`)"
					:key="`${image}.${i}.slide_product_photo.path`"
					class="slide-product-bg"
					:value="`${prefix}.slider.${i}.slide_product_photo_background`"
					:base="{height: 250}"
					:md="{height: 300}"
					:lg="{height: 350}"
					:xl="{height: 400}"
					:xxl="{height: 440}"
					:class="{active: i === activeSlide}"
				/>
			</template>
		</div>
		<div class="images-wrapper product-images">
			<template
				v-for="(image, i) in this.value.slider"
			>
				<cms-image
					v-if="isImagePathCorrect(`${prefix}.slider.${i}.slide_product_photo`)"
					:key="`${image}.${i}.slide_product_photo.path`"
					class="slide-product-img"
					:value="`${prefix}.slider.${i}.slide_product_photo`"
					:base="{height: 250}"
					:md="{height: 300}"
					:lg="{height: 350}"
					:xl="{height: 400}"
					:xxl="{height: 440}"
					:class="{
					active: i === activeSlide,
					prev: i < activeSlide,
					next: i > activeSlide
				}"
				/>
			</template>
			<div class="price-wrapper" v-if="showDiscount">
				<base-svg name="price_wrapper"/>
				<div class="prices-wrapper">
					<cms-text
						:value="`${prefix}.slider.${activeSlide}.product_price_discount`"
						:props="{color: 'white', variant: 'price'}"
						class="discount"
					/>
					<cms-text
						:value="`${prefix}.slider.${activeSlide}.product_price_before`"
						:props="{color: 'white', variant: 'price'}"
						class="old-price"
					/>
					<cms-text
						:value="`${prefix}.slider.${activeSlide}.product_price`"
						:props="{color: 'white', variant: 'price'}"
						class="new-price"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { getValue } from 'utils/objects'

export default {
	data () {
		return {
			showDiscount: false
		}
	},
	props: {
		prefix: String,
		value: Object,
		activeSlide: Number
	},
	watch: {
		activeSlide: function (newVal) {
			this.value.slider[newVal].discount ? this.showDiscount = true : this.showDiscount = false
		}
	},
	methods: {
		isImagePathCorrect (valuePath) {
			const path = getValue(valuePath, this.$app.page.value, null)
			if (!path) return false

			return path
		}
	},
	mounted () {
		this.showDiscount = this.value.slider[0].discount
	}
}
</script>
<style lang="scss">
.slide-product-wrapper {
	position: absolute;
	top: 180px;
	right: 0px;
	z-index: 120;
	@include media-breakpoint-up(xl) {
		top: 210px;
		right: calc((100vw - 25%) * 0.2 );
	}
	.slide-product-bg {
		top: 90px;
		left: -60px;
		opacity: 0;
		//visibility: hidden;
		transition: all 0.5s ease;
		pointer-events: none;
		position: absolute;
		&.active {
			position: relative;
			transform: translateX(0);
			opacity: 1;
			//visibility: visible;
		}
		@include media-breakpoint-up(lg) {
			top: 30px;
			left: -150px;
		}
		@include media-breakpoint-up(xl) {
			top: 20px;
			left: -30px;
		}
		@include media-breakpoint-up(xxl) {
			top: -30px;
			left: -150px;
		}
	}
	.product-images {
		position: absolute;
		top: 100px;
		left: -10px;
		pointer-events: none;

		@include media-breakpoint-up(md) {
			left: -30px;
		}
		@include media-breakpoint-up(lg) {
			top: 30px;
			left: -50px;
		}
		@include media-breakpoint-up(xl) {
			top: 30px;
			left: 30px;
		}
		@include media-breakpoint-up(xxl) {
			left: 0;
		}
	}
	.slide-product-img {
		position: absolute;
		opacity: 0;
		transition: opacity 0.3s ease, transform 0.5s ease;
		&.prev {
			transform: translateX(300px);
			z-index: 2;
		}
		&.next {
			transform: translateX(-300px);
		}
		&.active {
			transform: translateX(0);
			opacity: 1;
		}
	}
	.images-wrapper {
		display: inline-block;
	}
	.price-wrapper {
		position: absolute;
		width: 120px;
		top: -15px;
		left: -120px;
		@include media-breakpoint-up(md) {
			top: 0;
		}
		@include media-breakpoint-up(lg) {
			width: 150px;
			//top: 10px;
			top: 25px;
			left: -210px;
		}
		@include media-breakpoint-up(xl) {
			left: -130px;
		}
		@include media-breakpoint-up(xxl) {
			left: -210px;
		}
	}
	.prices-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.old-price {
			opacity: .3;
			margin: -1rem 0 -1.5rem 0;
			font-size: 1rem;
			text-decoration: line-through;
			@include media-breakpoint-up(lg) {
				font-size: 1.4rem;
				margin: -0.5rem 0 -1rem 0;
			}
		}
		.discount {
			font-size: 1.8rem;
			@include media-breakpoint-up(lg) {
				font-size: 2.4rem;
			}
		}
		.new-price {
			font-size: 1.8rem;
			@include media-breakpoint-up(lg) {
				font-size: 2.2rem;
			}
		}
	}
}
</style>
