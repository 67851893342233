<template>
	<base-container class="slide-content">
		<div class="slide-texts">
			<cms-text
				v-for="(text, i) in this.value.slider"
				:key="`${text}.${i}.slide_title`"
				:props="{variant: 'hero-header', color: 'white'}"
				:value="`${prefix}.slider.${i}.slide_title`"
				:class="{active: i === activeSlide}"
				class="slide-text"
			/>
		</div>
		<div class="slide-texts">
			<cms-text
				v-for="(text, i) in this.value.slider"
				:key="`${text}.${i}.text_under_title`"
				:props="{variant: 'hero-header-medium', color: 'white'}"
				:value="`${prefix}.slider.${i}.text_under_title`"
				:class="{active: i === activeSlide}"
				class="slide-text"
			/>
		</div>
		<cms-multi-section
			v-for="(image, i) in this.value.slider"
			:key="`${image}.${i}.slide_photo.path`"
			class="slide-buttons"
			:value="`${prefix}.slider.${i}.slider_buttons`"
			:class="{active: i === activeSlide}"
		/>
	</base-container>
</template>
<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'

export default {
	components: {
		CmsMultiSection
	},
	props: {
		prefix: String,
		value: Object,
		activeSlide: Number
	}
}
</script>
<style lang="scss">
.slide-content {
	position: absolute;
	width: 100%;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	padding: 4.5rem 2.5rem 0;
	@include media-breakpoint-up(xl) {
		display: block;
	}
}
.slide {
	&-texts {
		position: relative;
	}
	&-text {
		position: absolute;
		top: 0;
		left: 0;
		margin-bottom: 2rem;
		opacity: 0;
		transition: opacity .3s ease ;
		z-index: 312;

		&.active {
			position: relative;
			opacity: 1;
		}
	}

	&-buttons {
		display: none;
		@include media-breakpoint-up(md) {
			position: relative;
			margin-top: 8rem;
			z-index: 123;
			& div.buttons-wrapper {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			&.active {
				display: block;
			}
		}
		@include media-breakpoint-up(xl) {
			margin-top: 10rem;
		}
		@include media-breakpoint-up(xl) {
			& div.buttons-wrapper {
				flex-direction: row;
			}
		}
	}
}
</style>
